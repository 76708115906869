<template>
    <CompTable ref="comp_table" :title="(orgName || '') + '逾期更新栏目清单'" :columns="columns" :table-api="tableApi"></CompTable>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"

export default {
    components: {
        CompTable,
    },

    data() {
        return {
            orgName: this.$core.getUrlParam("orgName"),
            tableApi: `/gateway/api/symanage/pc/columnAlarm/listByDataScopeId?dataScopeId=${this.$core.getUrlParam("dataScopeId")}`,
            columns: [
                {
                    title: "栏目名称",
                    key: "columnName",
                },
                {
                    title: "栏目编码",
                    key: "columnCode",
                },
                {
                    title: "最后一次数据更新时间",
                    render: (h, params) => {
                        return h("span", params.row.lastTime ? this.$core.formatDate(new Date(params.row.lastTime), "yyyy-MM-dd hh:mm:ss") : "-")
                    },
                },
                {
                    title: "逾期天数",
                    key: "overdueDay",
                },
            ],
        }
    },
}
</script>

<style></style>
